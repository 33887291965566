import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade, EffectCreative, Thumbs } from 'swiper/modules';
import 'swiper/css';


var myMainSwiper = new Swiper('.main-slider', {
  modules: [Pagination, Autoplay, EffectFade],
  loop: true,
  effect: "fade",
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 9000,
    disableOnInteraction: false
  },
  on: {
    init: function () {
      checkAndPlayVideo();
      adjustAutoplayDelay(this);
    },
    slideChangeTransitionEnd: function () {
      checkAndPlayVideo();
      adjustAutoplayDelay(this);
    }
  }
});

function adjustAutoplayDelay(swiper) {
  var activeIndex = swiper.realIndex;

  if (activeIndex === 0) {
    swiper.params.autoplay.delay = 4000;
  }
  else if (activeIndex === 1) {
    swiper.params.autoplay.delay = 9000;
  }
  else {
    swiper.params.autoplay.delay = 15000;
  }

  swiper.autoplay.stop();
  swiper.autoplay.start();
}

function checkAndPlayVideo() {
  var firstSlide = document.querySelector('.first-slide');
  var video = firstSlide.querySelector('.video-wrapper video');

  if (video) {
    if (isElementInViewport(video)) {
      video.play();
    } else {
      video.pause();
      video.currentTime = 0;
    }
  }
}

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

document.querySelectorAll('.swiper-pagination-bullet').forEach((bullet, index) => {
  bullet.addEventListener('mouseover', () => {
    myMainSwiper.slideToLoop(index);
  });
});




//====second slide on main slider=========

document.addEventListener("DOMContentLoaded", function () {
  const interval = 2000;
  const fadeDuration = 1000;
  let currentIndex = 0;

  const images = document.querySelectorAll('.home-banner .anim-change-img');

  if (images.length > 0) {
    function changeImage() {
      images[currentIndex].classList.remove('active');

      currentIndex = (currentIndex + 1) % images.length;

      images[currentIndex].classList.add('active');
    }

    // Set the first image as visible
    images[currentIndex].classList.add('active');

    setInterval(() => {
      changeImage();
    }, interval);
  }
});


//===============================================


var myRealizedSwiper = new Swiper('.realized-slider ', {
  modules: [Pagination, Navigation, Autoplay],
  loop: true,
  speed: 1500,
  slidesPerView: "auto",
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: ".swiper-pagination",
    type: "progressbar",
  },
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true
  },
});

var productsSwiper = new Swiper('.products-swiper', {
  modules: [Navigation],
  loop: true,
  slidesPerView: 1,
  speed: 900,
  watchSlidesProgress: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    600: {
      slidesPerView: 2,
    },
    1025: {
      slidesPerView: 1,
    },
  },
});

var myDocSwiper = new Swiper('.documentation-slider ', {
  modules: [Pagination, Navigation],
  loop: true,
  speed: 500,
  slidesPerView: "1",
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: ".swiper-pagination",
    type: "progressbar",
  },
});

document.querySelectorAll('.options-slider').forEach((slider, index) => {
  const nextButton = slider.querySelector('.swiper-button-next');
  const prevButton = slider.querySelector('.swiper-button-prev');
  const pagination = slider.querySelector('.swiper-pagination');

  new Swiper(slider, {
    modules: [Pagination, Navigation],
    loop: true,
    speed: 200,
    slidesPerView: "auto",
    navigation: {
      nextEl: nextButton,
      prevEl: prevButton,
    },
    pagination: {
      el: pagination,
      type: "progressbar",
    },
  });
});



var mySpecificationSwiper = new Swiper('.specification-slider', {
  modules: [Pagination, Navigation],
  slidesPerView: "auto",
  speed: 500,
  navigation: {
    nextEl: '.specification-next',
    prevEl: '.specification-prev',
  },
  pagination: {
    el: ".swiper-pagination-specification",
    type: "progressbar",
  },
});

var designUnitsSwiper = new Swiper('.design-units-swiper', {
  modules: [Navigation],
  loop: true,
  slidesPerView: 1,
  speed: 900,
  watchSlidesProgress: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    600: {
      slidesPerView: 2,
    },
  },
});



const swiperContainers = document.querySelectorAll('.prod-adaptive-swiper-bullets');

swiperContainers.forEach((container, index) => {
  const mainSliderSelector = `.prod-adaptive-swiper-main-${index}`;
  const bulletSliderSelector = `.prod-adaptive-swiper-bullets-${index}`;

  container.classList.add(`prod-adaptive-swiper-bullets-${index}`);
  container.closest('.prod-content-wrapper').querySelector('.prod-adaptive-swiper-main').classList.add(`prod-adaptive-swiper-main-${index}`);

  const fixedWindowsLink = container
    .closest('.prod-content-wrapper')
    .querySelector('.fixed-windows-link');

  const prodAdaptiveSwiperBullets = new Swiper(bulletSliderSelector, {
    slidesPerView: 5,
    centeredSlides: true,
    spaceBetween: 46,
    freeMode: true,
    watchSlidesProgress: true,
    on: {
      slideChange: () => {
        const activeIndex = prodAdaptiveSwiperBullets.activeIndex;
        prodAdaptiveSwiperMain.slideTo(activeIndex);
        centerActiveSlide(prodAdaptiveSwiperBullets);
      }
    }
  });

  const slides = container.querySelectorAll('.swiper-slide');

  slides.forEach((slide, idx) => {
    slide.addEventListener('click', () => {
      prodAdaptiveSwiperMain.slideTo(idx);
      centerActiveSlide(prodAdaptiveSwiperBullets);
    });
  });

  const prodAdaptiveSwiperMain = new Swiper(mainSliderSelector, {
    slidesPerView: 1,
    thumbs: {
      swiper: prodAdaptiveSwiperBullets,
    },
    on: {
      slideChange: () => {
        const activeIndex = prodAdaptiveSwiperMain.activeIndex;
        prodAdaptiveSwiperBullets.slideTo(activeIndex);
        centerActiveSlide(prodAdaptiveSwiperBullets);
        updateFixedLink(fixedWindowsLink, prodAdaptiveSwiperMain);
      }
    }
  });

  function centerActiveSlide(swiper) {
    const activeSlideIndex = swiper.activeIndex;
    swiper.slideTo(activeSlideIndex, 0);
  }

  function updateFixedLink(linkElement, mainSwiper) {
    const activeSlide = mainSwiper.slides[mainSwiper.activeIndex];
    const activeLink = activeSlide.querySelector('a');
    if (linkElement && activeLink) {
      linkElement.href = activeLink.href;
    }
  }

  updateFixedLink(fixedWindowsLink, prodAdaptiveSwiperMain);
});
